import React from 'react';

function NoDataCard({
    children = 'WE ARE IN THE PROCESS OF COLLECTING MORE DATA FOR THIS SECTION',
}: {
    children?: string | JSX.Element;
}) {
    return (
        <p
            className="text-center py-4 px-16 bg-[#2E3037] rounded-xl text-[#81859B] text-xs"
            style={{ lineHeight: '1.1rem' }}
        >
            {children}
        </p>
    );
}

export default NoDataCard;
