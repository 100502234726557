import classNames from 'classnames';
import React from 'react';

type CustomTextType =
    | 'body/regular'
    | 'body/small'
    | 'allCaps/regular'
    | 'allCaps/small'
    | 'Button/regular'
    | '14Semi'
    | 'Button/Small';

function Text({
    type,
    children,
    className,
    ...rest
}: { type: CustomTextType } & JSX.IntrinsicElements['span']) {
    return (
        <span
            className={classNames(
                'font-semibold',
                {
                    'font-semibold text-xs leading-[16.39px]': type === 'body/regular',
                    'font-light text-xs': type === 'body/small',
                    'uppercase text-xs': type === 'allCaps/regular',
                    'uppercase text-xxs': type === 'allCaps/small',
                    'text-[15px]': type === 'Button/regular',
                    'text-[11px]': type === 'Button/Small',
                    'text-sm': type === '14Semi',
                },
                className,
            )}
            {...rest}
        >
            {children}
        </span>
    );
}

export default Text;
export type { CustomTextType };
