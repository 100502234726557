import { API_URL } from '../../buildConfig/processEnv';

type UserDetailsForm = {
    firstName: string;
    lastName: string;
    work: string;
    freeWork: string;
    title: string;
};
const userDetailsService = async ({
    token,
    form,
}: {
    token?: string;
    form?: UserDetailsForm;
}): Promise<{
    userDetailsData?: { success?: boolean };
    userDetailsError?: Error;
}> => {
    const params = {
        value: {
            title: form.title,
            occupation: form.work === 'Other' ? form.freeWork : form.work,
        },
    };
    let userDetailsError;

    const url = new URL(`${API_URL}/auth-service/api/auth/settings/user_details/`);
    const submit = async () => {
        const response = await fetch(url.toString(), {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
            },
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(params),
        });

        if (response.status >= 400) {
            userDetailsError = response.status;
            return false;
        }

        const results = await response.json();
        return results;
    };

    const userDetailsData = await submit();
    return {
        userDetailsData,
        userDetailsError,
    };
};
type GetUserDetailsData = {
    user: 6;
    key: string;
    value: {
        title?: string;
        occupation?: string;
    };
};
const getUserDetailsService = async ({
    token,
}: {
    token?: string;
}): Promise<{ userDetailsData?: GetUserDetailsData; userDetailsError?: Error }> => {
    let userDetailsError;

    const url = new URL(`${API_URL}/auth-service/api/auth/settings/user_details/`);
    const userDetails = async () => {
        const response = await fetch(url.toString(), {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`,
            },
            method: 'GET',
            credentials: 'include',
        });

        if (response.status >= 400) {
            userDetailsError = response.status;
            return false;
        }

        const results = await response.json();
        return results;
    };

    const userDetailsData = await userDetails();
    return {
        userDetailsData,
        userDetailsError,
    };
};

export default userDetailsService;
export { getUserDetailsService };
export type { UserDetailsForm };
