import '../styles/global.css';
import type { AppProps } from 'next/app';
import { QueryClientProvider } from '@tanstack/react-query';
import { MantineProvider } from '@mantine/core';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Notifications } from '@mantine/notifications';
import { hotjar } from 'react-hotjar';
import Head from 'next/head';
import { useEffect, useRef } from 'react';
import isMobile from 'ismobilejs';

import AppContextProvider from '../contexts/appContext';
import { queryClient } from '../utils/reactQueryConfig';
import MarketRecap from './market-recap';

export default function App({ Component, pageProps }: AppProps) {
    useEffect(() => {
        const id = Number(process.env.NEXT_PUBLIC_HOTJAR_ID) || 0;
        hotjar.initialize(id, 6);
    }, []);
    const mobile = useRef(isMobile().phone);

    return (
        <MantineProvider
            // ? This is because the highest z-index is 400 (NavBar)
            theme={{
                colorScheme: 'dark',
                fontFamily: 'inherit',
                headings: {
                    fontFamily: 'inherit',
                    fontWeight: '500',
                    sizes: {
                        h1: { fontSize: '28px' },
                        h2: { fontSize: '22px' },
                        h3: { fontSize: '18px' },
                        h4: { fontSize: '16px' },
                        h5: { fontSize: '14px' },
                        h6: { fontSize: '12px', lineHeight: '1rem' },
                    },
                },
                components: {
                    Modal: {
                        classNames: {
                            overlay: 'z-[500]',
                            inner: '!z-[501]',
                            content: 'bg-primary',
                            header: 'bg-primary',
                        },
                    },
                },
            }}
            withGlobalStyles
        >
            <Notifications />
            <Head>
                {/* Disabling auto zoom in input for Safari on iPhone and setting a smaller initial scale */}
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=0.85, maximum-scale=0.85"
                />
            </Head>

            <QueryClientProvider client={queryClient}>
                <AppContextProvider>
                    {mobile.current ? <MarketRecap /> : <Component {...pageProps} />}
                </AppContextProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </MantineProvider>
    );
}
