import { useRouter } from 'next/router';
import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button } from '@mantine/core';
import isMobile from 'ismobilejs';
import classNames from 'classnames';
import NavBar, { AllPagesType, ArenaPagesType, BennuPagesType } from './navBar';
import ReactHeap from '../utils/heap';
import { HEAP_ID } from '../buildConfig/processEnv';
import { useAppContext } from '../contexts/appContext';
import Login from './login';
import AppLoading from './appLoading';
import RegisterForm from './userDetails';
import { UserGroup } from '../types/user';
import NoDataCard from './noDataCard';
import { hideFromBennu } from '../utils/auth';
import MobileNavBar from './MobileNavBar';

const DETAIL_OPTIONS = ['true', 'skip'];
const bennuPages: BennuPagesType[] = ['sales-assistant', 'market-overview'];

const hideFromBennuPages: AllPagesType[] = ['growth-actions'];
const arenaPages: ArenaPagesType[] = ['developer'];

function Layout({
    children,
    title,
    className,
}: {
    children: React.ReactNode;
    title: string;
    className?: string;
}) {
    const { user, fetchingToken, handleUser, verifyToken } = useAppContext();
    const router = useRouter();
    const [isDetails, setIsDetails] = useState<boolean>(false);

    useEffect(() => {
        if (HEAP_ID) ReactHeap.initialize(HEAP_ID);
    }, []);

    useEffect(() => {
        if (!user || !user?.showUserDetails) return;
        function checkDetails() {
            const timeout = Number(localStorage.getItem('detailsTimeout'));
            if (timeout && timeout !== moment().day()) {
                localStorage.removeItem('detailsSubmitted');
                localStorage.removeItem('detailsTimeout');
            }
            if (
                !DETAIL_OPTIONS.includes(localStorage.getItem('detailsSubmitted')) &&
                user?.showUserDetails
            )
                setIsDetails(true);
        }
        checkDetails();
    }, [router, user, user?.showUserDetails]);

    // ? Possible Fix for having the login screen showing up randomly.
    useEffect(() => {
        const userDataLocalStorage = localStorage.getItem('user-data');
        const userDataToken = localStorage.getItem('token');
        if (!user?.id && userDataLocalStorage) {
            verifyToken(userDataToken);
            // ? [[[Optimistic UI]]]
            handleUser({
                ...JSON.parse(userDataLocalStorage),
                token: userDataToken,
                isLoggedIn: !!userDataToken,
            });
        }
    }, [handleUser, user?.id, verifyToken]);

    // ? Page Lock Logic
    const bennuAndArenaPagesCondition =
        bennuPages.includes(router.route.replace('/', '') as BennuPagesType) &&
        user?.groups &&
        (!['arena-staff', 'bennu-users'].some(group => user.groups?.includes(group as UserGroup)) ||
            user.groups?.includes('force-remove-groups'));
    const arenaPagesCondition =
        arenaPages.includes(router.route.replace('/', '') as ArenaPagesType) &&
        user?.groups &&
        !user.groups.includes('arena-staff');
    const hideFromBennuPagesCondition =
        hideFromBennuPages.includes(router.route.replace('/', '') as AllPagesType) &&
        hideFromBennu(user?.groups);

    useEffect(() => {
        if (typeof window === 'undefined') return;
        if (hideFromBennuPagesCondition) {
            router.replace({ pathname: '/' });
            return;
        }
        if (bennuAndArenaPagesCondition) {
            router.replace({ pathname: '/' });
            return;
        }
        if (arenaPagesCondition) {
            router.replace({ pathname: '/' });
        }
        // ? Adding [[[router]]] breaks all pages
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [arenaPages, bennuAndArenaPagesCondition, router.pathname, user.groups]);

    const mobile = isMobile().phone;

    if (fetchingToken) return <AppLoading>Authenticating user</AppLoading>;
    if (!user || !user.token || !user.isLoggedIn) return <Login />;
    if (!user?.groups) return <AppLoading>Loading user data</AppLoading>;

    if ((arenaPagesCondition || bennuAndArenaPagesCondition) && typeof window !== 'undefined')
        return (
            <div className="flex flex-col gap-3 justify-center items-center w-full h-screen">
                <NoDataCard>This Page is off Limits</NoDataCard>
                <Button type="button" onClick={() => router.back()}>
                    Go Back
                </Button>
            </div>
        );
    return (
        <div className="dark">
            <Head>
                <title>{title}</title>
                <link rel="shortcut icon" href="images/favicon.png" />
            </Head>
            <RegisterForm setIsDetails={setIsDetails} isDetails={isDetails} />
            {!isDetails && (
                <div className="flex">
                    {/* // ? Product Intelligence Filter's zIndex is 301 */}
                    <div
                        className={classNames('fixed z-[400]', {
                            hidden: mobile,
                        })}
                    >
                        <NavBar />
                    </div>
                    {mobile && (
                        <div>
                            <MobileNavBar />
                        </div>
                    )}
                    <div
                        className={classNames(`p-4 ml-20 grow`, className, {
                            '!ml-0 max-w-[100vw] overflow-auto': mobile,
                        })}
                    >
                        {children}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Layout;
