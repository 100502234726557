import React from 'react';
import { Image, Navbar } from '@mantine/core';
import Link from 'next/link';
import { useRouter } from 'next/router';

const NavItemsWrapper = ({
    children,
    className,
}: {
    children: JSX.Element;
    className?: string;
}) => <div className={`flex flex-col ${className}`}>{children}</div>;

function NavBar() {
    const router = useRouter();

    return (
        <Navbar className="flex flex-row fixed top-0 w-screen gap-3 h-14 p-4 glass-effect bg-secondary-bg">
            <div className="flex">
                <Link id="navbar-home" href="/">
                    <Image
                        src="images/favicon.png"
                        width={30}
                        height={30}
                        alt="logo"
                        onClick={() => router.push('/')}
                        className="hover:bg-shadow-300 cursor-pointer"
                    />{' '}
                </Link>
            </div>
            <h1 className="flex pt-1 text-[#e2e8f0]">Nielsen Market Recap</h1>
        </Navbar>
    );
}

export default NavBar;
