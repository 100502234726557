import { QueryClient } from '@tanstack/react-query';

const CACHE_MAX_DURATION = 10 * 60 * 1000; // 10 MINUTES

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: CACHE_MAX_DURATION,
            // ? This will decrease the number of requests - 5 Mins sounds reasonable
            staleTime: CACHE_MAX_DURATION / 2,
        },
    },
});

export { queryClient };
