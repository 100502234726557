/* eslint-disable @next/next/no-img-element */
import { Button, CSSObject, Input, PasswordInput } from '@mantine/core';
import Head from 'next/head';
import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useAppContext } from '../contexts/appContext';
import Text from './utils/text';

const commonStyling: CSSObject = { textAlign: 'center', background: '#22262d', color: '#B1B5C1' };

const Login = () => {
    const { googleLogin, microsoftLogin, credentialLogin } = useAppContext();
    const [formError, setFormError] = useState<string>();
    const handleSubmit = useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            const { username, password } = e.currentTarget as typeof e.currentTarget & {
                username: { value: string };
                password: { value: string };
            };
            const res = await credentialLogin({
                username: username.value,
                password: password.value,
            });
            if (res === 'You do not have permission to perform this action.') {
                setFormError('Invalid Credentials');
                username.value = '';
                password.value = '';
            }
        },
        [credentialLogin],
    );

    return (
        <div className="flex flex-col justify-center items-center h-screen">
            <Head>
                <title>Arena</title>
                <link rel="shortcut icon" href="images/favicon.png" />
            </Head>
            <div className="flex flex-row justify-center items-center">
                <img
                    src="images/arenaLogo.svg"
                    alt="Arena"
                    style={{
                        height: '40px',
                        width: 'auto',
                    }}
                    title="Arena"
                />
                <img
                    alt="arena"
                    src="images/arenaLogoFull.svg"
                    style={{
                        height: '21px',
                        marginBottom: '10px',
                        width: 'auto',
                        color: '#fff',
                        fill: '#fff',
                    }}
                />
            </div>
            <div className="flex gap-2 p-3 mb-3 border-b-1 border-border-color">
                <Button
                    type="button"
                    variant="default"
                    data-testid="login-google"
                    onClick={() => {
                        googleLogin();
                    }}
                >
                    Google Login
                </Button>
                <Button
                    type="button"
                    variant="default"
                    data-testid="login-microsoft"
                    onClick={() => {
                        microsoftLogin();
                    }}
                >
                    Microsoft Login
                </Button>
            </div>
            <div>
                <form onSubmit={handleSubmit} className="min-w-[275px] w-full flex flex-col gap-2">
                    <Input
                        type="text"
                        name="username"
                        id="username"
                        placeholder="Username"
                        className="focus-within:!border-secondary"
                        styles={{
                            input: {
                                ...commonStyling,
                                paddingRight: '26px',
                            },
                        }}
                    />
                    <PasswordInput
                        type="password"
                        name="password"
                        placeholder="Password"
                        id="password"
                        className="focus-within:!border-secondary"
                        styles={{
                            innerInput: { ...commonStyling },
                        }}
                    />
                    <Button type="submit" variant="default">
                        Login
                    </Button>
                    <Text
                        type="Button/regular"
                        className={classNames('text-red-600 text-center', {
                            hidden: !formError,
                        })}
                    >
                        {formError}
                    </Text>
                </form>
            </div>
        </div>
    );
};

export default Login;
