import React from 'react';

function AppLoading({ children }: { children: string }) {
    return (
        <div className="flex justify-center items-center text-center w-full h-screen">
            <p className="w-96 text-gray-300">{children}</p>
        </div>
    );
}

export default AppLoading;
