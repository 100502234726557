import React, { useState } from 'react';
import { Image, Navbar } from '@mantine/core';
import { ShoppingCart } from 'tabler-icons-react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import {
    BracketsAngle,
    Gear,
    Handshake,
    Question,
    Scroll,
    ShuffleAngular,
    Storefront,
    Table,
    TrendUp,
    UserFocus,
} from 'phosphor-react';
import { useAppContext } from '../contexts/appContext';
import StyledTooltip from './utils/styledTooltip';
import { hideFromBennu } from '../utils/auth';

const NavItemsWrapper = ({
    children,
    className,
}: {
    children: JSX.Element;
    className?: string;
}) => <div className={`flex flex-col ${className}`}>{children}</div>;

const covertHrefToRoute = (href: string) => {
    const index = href.indexOf('?');
    if (index > -1) {
        return href.substring(0, index);
    }
    if (href.includes('/')) return href;
    return `/${href}`;
};

// ? Brand is added because of the difference between Page's Brands
// const blackListedQueries = ['brand'];

// ? Pages with URL Params
type PagesType =
    | '/'
    | 'consumers'
    | 'growth-actions'
    | 'integrations'
    | 'intelligence'
    | 'productSearch'
    | 'settings'
    | 'feedback'
    | 'developer'
    | 'market-recap'
    | 'sales-assistant'
    | 'banner-assistant'
    | 'market-overview';

type BennuPagesType = 'market-recap' | 'sales-assistant' | 'market-overview';

type ArenaPagesType = 'developer';

type AllPagesType =
    | '/'
    | 'consumers'
    | 'growth-actions'
    | 'integrations'
    | 'intelligence'
    | 'productSearch'
    | 'settings'
    | 'feedback'
    | BennuPagesType
    | ArenaPagesType;

const PAGE_NAMES = {
    '/': 'Home',
    consumers: 'Consumers',
    'growth-actions': 'Growth Actions',
    integrations: 'Integrations',
    intelligence: 'Intelligence',
    productSearch: 'Product Search',
    settings: 'Settings',
    feedback: 'Feedback',
    'banner-assistant': 'Banner Assistant',
    developer: 'Developer for Arena Staff',
    'market-recap': 'Nielsen Market Recap',
    'sales-assistant': 'Sales Assistant',
    'market-overview': 'Market Overview',
};
const ActiveLink = ({
    href,
    children,
    className = '',
    id,
    routerQueryKeys = [],
    shareRoutesQueryKeysWith = [],
    sharableKeys = [],
}: {
    href: PagesType;
    children: React.ReactNode;
    className?: string;
    id: string;
    routerQueryKeys?: string[];
    sharableKeys?: string[];
    shareRoutesQueryKeysWith?: Array<PagesType>;
}) => {
    const { pathname, ...router } = useRouter();
    const [hrefWithCommonQueries, setHrefWithCommonQueries] = useState<string>(href);

    return (
        <StyledTooltip
            position="right"
            withArrow
            classNames={{
                tooltip: '!whitespace-nowrap',
            }}
            width="auto"
            arrowSize={12}
            label={PAGE_NAMES[href]}
        >
            <div
                id={id}
                className="rounded-lg flex justify-center items-center opacity-50 hover:opacity-100 hover:cursor-pointer"
                style={{
                    backgroundColor:
                        pathname === covertHrefToRoute(href)
                            ? 'rgba(var(--shadow),0.9)'
                            : undefined,
                    opacity: pathname === covertHrefToRoute(href) ? 1 : undefined,
                }}
                onFocus={() => {
                    if (pathname === covertHrefToRoute(href)) return;
                    if (!shareRoutesQueryKeysWith.includes(pathname.replace('/', '') as PagesType))
                        return;
                    const queriesArr = Object.entries(router.query);
                    if (queriesArr.length === 0) return;

                    const commonQueries = queriesArr.reduce(
                        (acc, [key, val]) =>
                            routerQueryKeys.includes(key) && sharableKeys.includes(key)
                                ? `${acc}${
                                      Array.isArray(val) ? val.join(`&${key}=`) : `&${key}=${val}`
                                  }`
                                : acc,
                        '?',
                    );
                    const newHref = commonQueries.replace('?&', '?');
                    if (newHref[newHref.length - 1] !== '?') {
                        // ? [hack] fix when we have a param in the ref.
                        setHrefWithCommonQueries(`/${href}&${newHref}`.replace('&?', '?'));
                    }
                }}
            >
                <Link
                    href={hrefWithCommonQueries}
                    key={id}
                    className={`py-2.5 px-3.5 ${className}`}
                >
                    {children}
                </Link>
            </div>
        </StyledTooltip>
    );
};

function NavBar() {
    const router = useRouter();
    const {
        user: { groups },
    } = useAppContext();
    const showBannerAssortmentNavItem =
        groups.filter(group => ['arena-staff', 'bennu-users'].includes(group)).length > 0 &&
        !groups.includes('force-remove-groups');

    const productFiltersParams = ['brand', 'size', 'pack', 'selections'];
    return (
        <Navbar className="flex flex-col w-20 gap-6 items-center p-5 glass-effect bg-secondary-bg">
            <div>
                <Link id="navbar-home" href="/">
                    <Image
                        src="images/favicon.png"
                        width={30}
                        height={30}
                        alt="logo"
                        onClick={() => router.push('/')}
                        className="hover:bg-shadow-300 cursor-pointer"
                    />
                </Link>
            </div>
            <NavItemsWrapper className="justify-between flex-grow">
                <>
                    <NavItemsWrapper className="flex-grow max-h-[70vh] overflow-auto gap-3">
                        <>
                            <ActiveLink
                                id="navbar-integrations"
                                href="integrations"
                                className="pl-3.5"
                            >
                                <ShuffleAngular size={30} strokeWidth={1} />
                            </ActiveLink>
                            <ActiveLink
                                id="navbar-prod-search"
                                href="productSearch"
                                className="pl-3.5"
                                routerQueryKeys={[
                                    ...productFiltersParams,
                                    'states',
                                    'minDate',
                                    'maxDate',
                                    'view',
                                    'metric',
                                    'legends',
                                ]}
                                sharableKeys={productFiltersParams}
                                shareRoutesQueryKeysWith={['growth-actions', 'consumers']}
                            >
                                <ShoppingCart size={30} strokeWidth={1} />
                            </ActiveLink>
                            <ActiveLink
                                id="navbar-consumers"
                                href="consumers"
                                className="pl-3.5"
                                routerQueryKeys={['brand']}
                                sharableKeys={['brand']}
                                shareRoutesQueryKeysWith={['growth-actions', 'productSearch']}
                            >
                                <UserFocus size={30} strokeWidth={1} />
                            </ActiveLink>
                            {showBannerAssortmentNavItem && (
                                <>
                                    <ActiveLink
                                        id="navbar-consumers"
                                        href="market-recap"
                                        className="pl-3.5"
                                    >
                                        <Scroll size={30} strokeWidth={1} />
                                    </ActiveLink>
                                    <ActiveLink
                                        id="navbar-consumers"
                                        href="sales-assistant"
                                        className="pl-3.5"
                                    >
                                        <Handshake size={30} strokeWidth={1} />
                                    </ActiveLink>
                                    <ActiveLink
                                        id="navbar-market-overview"
                                        href="market-overview"
                                        className="pl-3.5"
                                    >
                                        <Table size={30} strokeWidth={1} />
                                    </ActiveLink>
                                </>
                            )}
                            {!hideFromBennu(groups) && (
                                <ActiveLink
                                    id="navbar-consumers"
                                    href="growth-actions"
                                    className="pl-3.5"
                                    routerQueryKeys={productFiltersParams}
                                    sharableKeys={productFiltersParams}
                                    shareRoutesQueryKeysWith={['consumers', 'productSearch']}
                                >
                                    <TrendUp size={30} strokeWidth={1} />
                                </ActiveLink>
                            )}
                            {groups.includes('arena-staff') && (
                                <>
                                    <ActiveLink
                                        id="navbar-developer"
                                        href="banner-assistant"
                                        className="pl-3.5"
                                    >
                                        <Storefront size={30} strokeWidth={1} />
                                    </ActiveLink>
                                    <ActiveLink
                                        id="navbar-developer"
                                        href="developer"
                                        className="pl-3.5"
                                    >
                                        <BracketsAngle size={30} strokeWidth={1} />
                                    </ActiveLink>
                                </>
                            )}
                        </>
                    </NavItemsWrapper>
                    <NavItemsWrapper className="pt-[6px] border-t border-t-gray-600 mb-12 gap-3">
                        <>
                            <ActiveLink id="navbar-settings" href="settings" className="pl-3.5">
                                <Gear size={30} weight="light" />
                            </ActiveLink>
                            <ActiveLink id="navbar-feedback" href="feedback" className="pl-3.5">
                                <Question size={30} strokeWidth={1} />
                            </ActiveLink>
                            <div className="absolute left-0 bottom-6">
                                <Image src="images/in_beta.svg" width={66} alt="beta" />
                            </div>
                        </>
                    </NavItemsWrapper>
                </>
            </NavItemsWrapper>
        </Navbar>
    );
}

export default NavBar;
export type { AllPagesType, ArenaPagesType, BennuPagesType };
