import styled from '@emotion/styled';
import Head from 'next/head';
import { Anchor, Button, Checkbox, Loader, Select, Text, TextInput, Title } from '@mantine/core';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { showNotification } from '@mantine/notifications';
import { Check, ExclamationMark } from 'tabler-icons-react';
import { AnimatePresence, motion } from 'framer-motion';
import { useAppContext } from '../contexts/appContext';
import userDetailsService from '../services/userDetails/userDetails';

const Container = styled.div`
    max-width: 30rem;
    @apply w-full;
`;

const boxAnimation = {
    key: 'box',
    initial: {
        y: '-50%',
        opacity: 0,
        scale: 0.5,
    },
    animate: {
        y: 0,
        opacity: 1,
        scale: 1,
    },
    exit: {
        y: '50%',
        opacity: 0,
        scale: 0.5,
    },
    transition: {
        duration: 1,
        ease: 'easeOut',
    },
};
const INPUT_STYLE = {
    input: {
        backgroundColor: '#1E2128',
    },
};
const WORK_LIST = [
    { value: 'Digital Marketing', label: 'Digital Marketing' },
    { value: 'E-Commerce', label: 'E-Commerce' },
    { value: 'Sales', label: 'Sales' },
    { value: 'Social Media', label: 'Social Media' },
    { value: 'Revenue Management', label: 'Revenue Management' },
    { value: 'Analytics', label: 'Analytics' },
    { value: 'Other', label: 'Other' },
];
const NOTIF_SETTINGS = {
    disallowClose: true,
    autoClose: 5000,
};

interface RegisterFormProps {
    setIsDetails: React.Dispatch<React.SetStateAction<boolean>>;
    isDetails: boolean;
}

const RegisterForm = ({ setIsDetails, isDetails }: RegisterFormProps) => {
    const { user } = useAppContext();
    const router = useRouter();
    const [form, setForm] = useState({
        work: '',
        freeWork: '',
        title: '',
        acceptsTos: false,
    });
    const [disable, setDisable] = useState(true);
    const [loading, setLoading] = useState(false);

    const submitDetails = useCallback(async () => {
        setLoading(true);
        const res = await userDetailsService({
            token: user?.token,
            form: { firstName: user?.firstName, lastName: user?.lastName, ...form },
        });
        if (res.userDetailsError)
            showNotification({
                ...NOTIF_SETTINGS,
                title: 'Error!',
                message: 'Please try again.',
                color: 'red',
                icon: <ExclamationMark />,
            });
        else {
            showNotification({
                ...NOTIF_SETTINGS,
                title: 'Thank You!',
                message: 'Your details have been submitted.',
                color: 'green',
                icon: <Check />,
            });
            localStorage.setItem('detailsTimeout', String(moment().day()));
            localStorage.setItem('detailsSubmitted', 'true');
            setLoading(false);
            setIsDetails(false);
        }
        return res;
    }, [user, form, setIsDetails]);

    const isCompleted = () => {
        if (form.work === 'Other') {
            if (form.freeWork !== '' && form.title !== '' && form.acceptsTos) {
                return true;
            }
            return false;
        }
        return form.work !== '' && form.freeWork === '' && form.title !== '' && form.acceptsTos;
    };

    useEffect(() => {
        setDisable(!isCompleted());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    // Redirects the user back to the app if details are already sent but keeps them in if they did not
    useEffect(() => {
        function checkDetails() {
            if (!user?.showUserDetails) setIsDetails(false);
        }

        const timeout = setTimeout(() => checkDetails(), 500);
        return () => {
            clearTimeout(timeout);
        };
    }, [router, setIsDetails, user?.showUserDetails]);

    return (
        <AnimatePresence>
            {isDetails && (
                <motion.div
                    {...boxAnimation}
                    className="flex absolute w-screen h-screen justify-center place-items-center hideScroll z-[500] bg-[#1E2128]"
                >
                    <Head>
                        <title>Fusion | Welcome to the Arena</title>
                        <link rel="shortcut icon" href="images/favicon.png" />
                    </Head>
                    <Container>
                        <div className="flex flex-col mb-4 text-center">
                            <Title order={2}>Welcome to the Arena, {user.firstName}</Title>
                            <span className="text-gray-400 text-sm mt-2">
                                Tell us a bit about yourself for an improved experience.
                            </span>
                        </div>
                        <div className="flex-row mt-8 border-solid shadow bg-secondary-bg border-6 backdrop-blur-20 p-5 rounded-2xl">
                            <div>
                                <Select
                                    label="What kind of work do you do?"
                                    placeholder="- Select -"
                                    className="mt-4"
                                    value={form.work}
                                    onChange={work => setForm({ ...form, work })}
                                    data={WORK_LIST}
                                    styles={() => INPUT_STYLE}
                                />
                                {form.work === 'Other' && (
                                    <TextInput
                                        label="Please tell us more"
                                        className="mt-4"
                                        styles={() => INPUT_STYLE}
                                        value={form.freeWork}
                                        onChange={event =>
                                            setForm({
                                                ...form,
                                                freeWork: event.currentTarget.value,
                                            })
                                        }
                                    />
                                )}
                            </div>
                            <div className="mt-4">
                                <TextInput
                                    label="Your Title"
                                    styles={() => INPUT_STYLE}
                                    value={form.title}
                                    onChange={event =>
                                        setForm({ ...form, title: event.currentTarget.value })
                                    }
                                />
                            </div>
                            <div className="mt-4">
                                <Checkbox
                                    label={
                                        <>
                                            I agree to the{' '}
                                            <Anchor size="sm" href="terms.pdf" target="_blank">
                                                Arena Terms of Service
                                            </Anchor>
                                        </>
                                    }
                                    checked={form.acceptsTos}
                                    onChange={event =>
                                        setForm({
                                            ...form,
                                            acceptsTos: event.currentTarget.checked,
                                        })
                                    }
                                />
                            </div>
                            <div className="text-right">
                                <Button
                                    sx={{
                                        paddingRight: 12,
                                        backgroundColor: '#245EF7 !important',
                                        borderRadius: '20px',
                                        width: '96px',
                                        height: '40px',
                                        radius: '20px',
                                        padding: '12px',
                                    }}
                                    className={`mt-6 ${disable && 'opacity-50'} !cursor-pointer`}
                                    disabled={disable || loading}
                                    onClick={submitDetails}
                                >
                                    {loading ? (
                                        <Loader speed={1} size={15} color="white" />
                                    ) : (
                                        <Text className="text-gray-200 text-xs">Submit</Text>
                                    )}
                                </Button>
                            </div>
                        </div>
                    </Container>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default RegisterForm;
