import dynamic from 'next/dynamic';
import Layout from '../components/layout';

const ScrollyTellingContainer = dynamic(
    import('../components/nielsenMarketRecap/scrollyTellingContainer'),
    {
        ssr: false,
    },
);

const ExecutiveSummary = () => (
    <Layout title="Market Overview" className="!p-0 w-full relative">
        <ScrollyTellingContainer />
    </Layout>
);

export default ExecutiveSummary;
